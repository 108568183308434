<template>
  <div>
    <Modal :options="{width:'40vw',type:'danger'}" @close="$emit('cancelar')">
      <form @submit.prevent="retroceder_etapa">
        <div class="title">Retroceder etapa</div>
        <div class="body">
          <div class="row form-group">
            <label for="retroceso" class="col-form-label col-sm-3">Razón de retroceso</label>
            <div class="col-sm-9">
              <textarea v-model="razon" class="form-control" name="retroceso" id="retroceso" cols="30" rows="10"></textarea>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Retroceder etapa</button>
              <button class="btn btn-danger" type="button" @click="$emit('cancelar')">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
      ,tipo_usuario: 0
    }
    ,data() {
      return {
        razon: null
      }
    }
    ,methods: {
      async retroceder_etapa() {
        try {
          if (this.razon == '')
            return this.$helper.showMessage('Error','Debes especificar la razón del retroceso de etapa','error','alert');

          let res;

          if (this.tipo_usuario == 0)
            res = (await apiSolicitudes.retroceder_etapa(this.solicitud.id, {razon: this.razon})).data;
          else
            res = (await apiSolicitudes.retroceder_etapa(this.solicitud.id, {razon: this.razon}, this.tipo_usuario)).data;

          this.$log.info('res', res);

          this.$emit('actualizar');
        }catch(e) {
          this.$log.info('error',e)
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>